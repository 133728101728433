/* src/styles.css */

.dashed-gradient-line-horizontal {
  width: 100%;
  height: 2px;
  position: relative;
  /*rotate: 90deg;*/
  background: linear-gradient(to right, #50929C var(--gradient-width, 100%), #ADB7B9 10%);
}

.dashed-gradient-line-horizontal::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 10px,
    white 10px,
    white 16px
  );
  mask-image: linear-gradient(to right, #50929C var(--gradient-width, 100%), #ADB7B9);
}

@media (prefers-color-scheme: dark) {
    .dashed-gradient-line-horizontal::before {
      background: repeating-linear-gradient(
              to right,
              transparent,
              transparent 10px,
              #000000 10px,
              #000000 16px
      );
    }
}

.circular-progress {
  --size: 250px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 20px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * 3.14159 * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
  animation: progress-animation 5s linear 0s 1 forwards;
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: #ddd;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 0s;
  stroke: #5394fd;
}


.button-container {
  position: relative;
  display: inline-block;
}

.sliding-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Adjust based on your layout */
  height: 100%;
  background-color: white;
  border-radius: 8px; /* Match your button's border-radius */
  transition: transform 0.3s ease;
}

.position-0 {
  transform: translateX(0); /* Adjust this based on the button's position */
}

.button-style {
  position: relative;
  z-index: 1;
  /* Your button styles */
}

.button-style.active {
  /* Styles for the active button */
}

.recharts-cartesian-grid{
  border-width: 2px !important;
  border-color: #1B7F8E !important;
}

.recharts-cartesian-axis{
  display: none !important;
}
