@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    background-color: #f0f0f0;
}
@media (prefers-color-scheme: dark) {
    #root {
        background-color: #1c2226;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/*add Satoshi font family*/
@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi/Satoshi-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Italic';
  src: url('./fonts/Satoshi/Satoshi-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./fonts/Satoshi/Satoshi-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('./fonts/Satoshi/Satoshi-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Black';
  src: url('./fonts/Satoshi/Satoshi-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('./fonts/Satoshi/Satoshi-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./fonts/Satoshi/Satoshi-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('./fonts/Satoshi/Satoshi-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Satoshi-Light';
  src: url('./fonts/Satoshi/Satoshi-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins/Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('./fonts/Poppins/Poppins-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./fonts/Poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('./fonts/Poppins/Poppins-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('./fonts/Poppins/Poppins-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Black';
    src: url('./fonts/Poppins/Poppins-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url('./fonts/Poppins/Poppins-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('./fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraBoldItalic';
    src: url('./fonts/Poppins/Poppins-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url('./fonts/Poppins/Poppins-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url('./fonts/Poppins/Poppins-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./fonts/Poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url('./fonts/Poppins/Poppins-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url('./fonts/Poppins/Poppins-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('./fonts/Poppins/Poppins-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('./fonts/Poppins/Poppins-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-ThinItalic';
    src: url('./fonts/Poppins/Poppins-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Nasalization';
    src: url('./fonts/Nasalization/Nasalization.ttf');
    font-weight: normal;
    font-style: normal;
}

.Calendar__weekDays {
    color: #8F9BB3 !important;
    font-family: "Satoshi-Medium" !important;
    font-size: 1.125rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    margin-bottom: 0 !important;
}
.Calendar__day {
    font-family: "Satoshi-Medium" !important;
    font-size: 1.125rem !important;
    margin-bottom: 0.5rem !important;
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
    min-height: 2rem!important;
    min-width: 2rem!important;
}
.Calendar__monthYear{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin:0!important;
    /*transform: none !important;*/

}
.Calendar__monthText {
    font-family: "Satoshi-Bold" !important;
    color: #334067;
    font-size: 1.25rem !important;
    text-align: center;
    margin:0 !important;
    transform: none!important;
}
.Calendar__yearText {
    font-family: "Satoshi-Medium" !important;
    color: #8F9BB3;
    font-size: 0.75rem !important;
    text-align: center;
    margin:0!important;
    transform: none!important;
}
.Calendar{
    padding-top: 0 !important;
    border-radius: 1.5rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

@media (prefers-color-scheme: dark) {
    .Calendar__weekDays { color: #8F9BB3 !important;}
    .Calendar__day { color: white !important; }
    .Calendar__day:hover { background-color: #1c2226 !important;}
    .Calendar__day.-selected { background-color: #1B7F8E !important; min-height: 2rem!important; }
    .Calendar__monthText { color: white !important;}
    .Calendar__yearText { color: white !important;}
}
.transition-max-height {
    transition: max-height 0.3s ease-in-out;
    background-color: #1c2226;
}

.max-h-screen {
    max-height: 100vh; /* or an appropriately large value */
}

.transition-rotate {
  transition: transform 0.3s ease-in-out; /* Adjust duration as needed */
}

.bar {
  height: 0;
  transition: height 0.8s ease-in-out;
  animation: growBar 0.8s ease-in-out forwards;
}

@keyframes growBar {
  from {
    height: 0;
  }
  to {
    height: var(--bar-height);
  }
}


.progress-ring {
    display: block;
    position: absolute;
    bottom: -1.68rem;
    left: -0.4rem;
    margin: 20px auto;
}

.progress-ring__background {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
    /*z-index: 100;*/
}

.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
    /*z-index: 100;*/
}
.DatePicker__calendarArrow{
    display: none;
}
.custom-datepicker-container {
    position: relative!important;
    display: inline-block!important;
    width: 3rem!important;
}

.custom-datepicker-container .Calendar {
    position: absolute!important;
    display: flex!important;
    width: 18rem!important;
    min-height: 18rem!important;
    top: -13.5rem!important;
    right: -4rem!important;
    transform: translateX(10px)!important; /* Adjust the horizontal spacing as needed */
    z-index: 1000!important; /* Ensure the calendar appears above other elements */
    border-width: 1px!important;
    border-color: #b2bac9!important;
}

.custom-datepicker-container .Calendar__sectionWrapper {
    min-height: 10rem!important;
}
@media(prefers-color-scheme: dark) {
    .custom-datepicker-container {
        color: #E2E8F0;
    }
    .custom-datepicker-container .Calendar{
        background-color: #2E3C41 !important;
        padding-top: 0rem!important;
        margin-right: 10rem;
    }

    .custom-datepicker-container.Calendar__monthText {
        font-size: 1rem!important;
    }

    .custom-datepicker-container.Calendar__weekDays {
        font-size: 1rem!important;
    }
    .custom-datepicker-container .Calendar__day {
        color: #E2E8F0!important;
        font-size:  1rem!important;
        padding: 0!important;
        margin: 0!important;

    }
    .custom-datepicker-container .Calendar__day:hover {
        background-color: #2D3748;
    }
    .custom-datepicker-container .Calendar__day.-selected {
        background-color: #0F9D76!important;
    }
}

